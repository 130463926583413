import React from "react";

export const V1 = () => {

    return (
        <>
            <iframe title="web-iframe" id='web-iframe' src="https://pratyush-nirwan.github.io/pcoder-1.0/">
            </iframe>
        </>
    )
}

export const V2 = () => {
    return (
        <>
            <iframe title="web-iframe" id='web-iframe' src="https://pratyush-nirwan.github.io/pcoder-2.0/">
            </iframe>
        </>
    )
}

export const V3 = () => {
    return (
        <>
            <iframe title="web-iframe" id='web-iframe' src="https://pratyush-nirwan.github.io/pcoder-2.0/">
            </iframe>
        </>
    )
}

